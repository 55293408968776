<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <div class="page-title-right d-flex">
              <div class="me-2 d-flex">
                <label class="col-form-label me-2">Year</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="year"
                    :options="years"
                    :clearable="false"
                    :reduce="(name) => name.id"
                    @option:selected="getPlansByYear($event)"
                  >
                  </v-select>
                </div>
              </div>
              <label class="col-form-label me-2">Month</label>
              <div style="width: 165px">
                <v-select
                  label="name"
                  v-model="month"
                  :options="months"
                  :clearable="false"
                  :reduce="(name) => name.id"
                  @option:selected="getPlansByMonth($event)"
                >
                </v-select>
              </div>
  
              <div @click="refreshData" class="icon-css">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>
            <h4 class="page-title">Behavior Evaluation</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-end">
                  <router-link :to="{ name: 'hrd-behavior-evaluation-create' }">
                    <button
                      v-show="!loading"
                      type="button"
                      class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                      v-if="hasPermissions(['module-group-create'])"
                    >
                      <i class="mdi mdi-plus-circle"></i> Add Record
                    </button>
                  </router-link>
                </div>
              <place-holder v-if="loading"></place-holder>
              <div class="table-responsive" v-if="!loading">
                <table
                  class="table table-striped dt-responsive w-100 mb-3"
                  id="behavior-evaluation-list-datatable"
                  v-if="!loading"
                >
                  <thead v-if="!loading">
                    <tr class="small">
                      <th>No.</th>
                      <th>Employee Name</th>
                      <th>Program Name</th>
                      <th>Batch</th>
                      <th>Total Score</th>
                      <th>Percentage</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="!loading">
                    <template v-for="(item, index) in behaviorEvaluation" :key="index">

                        <tr>
                          <td>{{ ++index }}</td>
                          <td>{{ item.behavior_assessments.employee_name }}</td>
                          <td>{{ item.behavior_assessments.program_name }}</td>
                          <td>{{ item.behavior_assessments.program_batch_no }}</td>
                          <td>{{ item.total_score }}</td>
                          <td>{{ item.percentage }} %</td>
                          <td>
                            <span>
                                
                              <router-link
                                class="action-icon"
                                :to="{
                                  name: 'hrd-behavior-evaluation-update',
                                  params: { id: item.id},
                                }"
                              >
                              <i class="mdi mdi-square-edit-outline"></i>
                              </router-link>
                            </span>
                          </td>
                        </tr>
                      </template>
                  </tbody>
                </table>
                <div class="text-center" v-if="behaviorEvaluation.length == 0">There is no data in table.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //Datatable Program Allocation
  import "datatables.net/js/jquery.dataTables.min.js";
  
  import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
  import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
  import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
  import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
  import $ from "jquery";
  import axios from "axios";
  import { useToast } from "vue-toastification";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  //Mixins
  import userHasPermissions from "../../../mixins/userHasPermissions";
  
  export default {
    components: {
      vSelect
    },
    mixins: [userHasPermissions],
    setup() {
      const toast = useToast();
      
      return { toast };
      
    },
    data() {
      return {
        behaviorEvaluation : [],
        year: "",
        yearId: "",
        years: [],
        months: [],
        month: "",
        loading: false,
        baseUrl: process.env.VUE_APP_BASE_URL,
        baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      };
    },
    methods: {
      async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          console.log(this.years)
          this.loading = false;
        });
    },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.month = "";
      this.getAllMonths(this.yearId);

    },
    async getAllMonths(year_id) {
      this.loading = true;
      this.$Progress.start();
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });
          this.loading = false;
          this.$Progress.finish();
        });
    },

    async getPlansByMonth(value) {
      if (this.yearId) {
        this.loading = true;
        this.$Progress.start();
        axios
          .get(
            `${this.baseUrl}admin/v1/hrdAssessment/hrd-behavior-evaluation?budget_year_id=${this.yearId}&month=${value.id}&type=ORI&emp_id=${this.employeeId}`
          )
          .then((response) => {
            this.behaviorEvaluation = response.data.data;   
            this.loading = false;
            this.$Progress.finish();
          });
      } else {
        this.month = "";
        this.toast.error("Please Choose Budget Year First!");
      }
    },

      async getBehaviorEvaluation() {
        this.loading = true;
        this.$Progress.start();
        await axios
            .get(`${this.baseUrl}admin/v1/hrd-behavior-evaluation`)
            .then((response) => {
              this.behaviorEvaluation = response.data.data;
              this.loading = false;
              this.$Progress.finish();
            })
            .catch((error) => {
              this.error = error.response;
              this.toast.error("Something went wrong.");
              this.loading = false;
            })
      },

      refreshData() {
        this.behaviorEvaluation = [];
      this.year = "";
      this.yearId = "";
      this.years = [];
      this.months = [];
      this.month = "";

        this.getBudgetYear();
        this.getBehaviorEvaluation();
      },
  
      clearFilter() {
        $.fn.dataTable.ext.search.pop();
        $("#behavior-evaluation-list-datatable").DataTable().draw();
      },
    },
    created() {
     
      this.clearFilter();
      this.getBudgetYear();
      this.getBehaviorEvaluation();
    },
   
  };
  </script>
  
  <style>
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
  }
  .status{
      padding: 0.4em;
      
  }
 
  </style>
  